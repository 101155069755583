import { Icons } from "../Components";

interface PropsType {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  href?: string;
  titleClassName?: string;
  subtitleClassName?: string;
}

const InfoBox = ({
  title,
  subtitle,
  children,
  href,
  titleClassName,
  subtitleClassName,
}: PropsType) => {
  const boxContent = (
    <div>
      <h3 className={titleClassName}>{title}</h3>
      {subtitle && <h6 className={`mt-1 font-weight-light ${subtitleClassName}`}>{subtitle}</h6>}
      {children}
    </div>
  );

  if (href) {
    return (
      <a href={href} className="text-link d-flex justify-content-between">
        {boxContent}
        <div className="align-self-center">
          <Icons.ChevronRight variant="secondary" />
        </div>
      </a>
    );
  } else {
    return boxContent;
  }
};

export default InfoBox;

/* TODO: remove following eslint rule when able */
/* eslint  @typescript-eslint/no-explicit-any: "off" */
import { ChangeEvent, FocusEvent, ReactNode } from "react";
import { Formik, Form as FormikForm, FormikTouched, FormikErrors, FormikProps, FormikHelpers } from "formik";
import * as yup from "yup";

import BootstrapForm from "react-bootstrap/Form";
import { Button } from '.'

export interface FormDataType { [key: string]: string | number | string[] | undefined }

interface fieldValuesPropsType<T extends FormDataType> {
  handleChange: (e: string | ChangeEvent<any>) => void;
  handleBlur?: (e: string | FocusEvent<any>) => void;
  values: T;
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
}

type FieldProps<T extends FormDataType> = {
  handleFocus?: (e: string | FocusEvent<any>) => void;
  placeholder?: string;
  name: keyof T;
  children?: ReactNode;
  type: string;
  autoFocus?: boolean;
  blurred?: boolean;
  className?: string;
  autoComplete?: string;
} & fieldValuesPropsType<T>;

interface SubmitButtonPropsType {
  isSubmitting: boolean;
  submitContent: ReactNode;
  className?: string
}

type onSubmitType<T extends FormDataType> = (values: T, feedback: FormikHelpers<T>) => void

interface FormPropsType<T extends FormDataType> {
  submitContent?: ReactNode;
  onSubmit: onSubmitType<T>;
  validationSchema?: yup.ObjectSchema<any, any, any, any>;
  initialValues: T;
  children: (values: {
    fieldValues: fieldValuesPropsType<T>
  }) => ReactNode;
  submitButtonClassName?: string;
  className?: string
}

type ErrorMessageProps = { message: string | undefined };

const SubmitButton = ({ className='', isSubmitting, submitContent }: SubmitButtonPropsType) => (
  <BootstrapForm.Group className={className}>
    <Button type="submit" className="btn btn-primary w-100" disabled={isSubmitting}>
      {isSubmitting ? "Please wait..." : submitContent}
    </Button>
  </BootstrapForm.Group>
);

function ErrorMessage ({ message }: ErrorMessageProps) {
  return(
    <>
      <div className={message ? 'is-invalid' : ''}/>
      <BootstrapForm.Control.Feedback type="invalid">
        {message}
      </BootstrapForm.Control.Feedback>
    </>
  )
}

function Field<T extends FormDataType>({
  children,
  name,
  type,
  placeholder,
  handleBlur,
  handleChange,
  handleFocus,
  values,
  touched,
  blurred,
  errors,
  className='',
  autoFocus=false,
  autoComplete='on'
}: FieldProps<T>) {
  return(
    <BootstrapForm.Group className={className}>
      {children && <BootstrapForm.Label htmlFor={String(name)}>{children}</BootstrapForm.Label>}
      <BootstrapForm.Control
        name={String(name)}
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={values[name]}
        className={`mt-3 p-3 border-0 bg-light ${blurred? "blurred" : ""} ${touched[name] && errors[name] ? "error" : ""}`}
        isInvalid={!!touched[name] && !!errors[name]}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
      />
      <BootstrapForm.Control.Feedback type="invalid">
        {String(errors[name])}
      </BootstrapForm.Control.Feedback>
    </BootstrapForm.Group>
  )
}

function Form<T extends FormDataType>({
  children,
  initialValues,
  onSubmit,
  validationSchema,
  submitContent,
  submitButtonClassName='',
  className='',
}: FormPropsType<T>) {
  return (
    <Formik
      className={className}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(formValues: FormikProps<T>) => {
        const { handleChange, handleBlur, values, touched, errors } = formValues

        return (
          <FormikForm className={className}>
            {children({ ...formValues, fieldValues: { handleChange, handleBlur, values, touched, errors } })}
            {submitContent && (<SubmitButton className={submitButtonClassName} isSubmitting={formValues.isSubmitting} submitContent={submitContent} />)}
          </FormikForm>
        );
      }}
    </Formik>
  );
}

const Exportable = { Form, Field, ErrorMessage };

export default Exportable;

import { useOutletContext } from "react-router-dom";

import { PageQuery, ErrorHandler } from '../Services';
import { Icons, InfoBox, Table } from '../Components';
import { flatMap, filter } from 'lodash';

interface PropsType {
  run: RunWithJobsType
}

const Header = ({ shortcode, runId, items }: { shortcode: string, runId: string, items: ItemType[] }) => {
  const pickup_number = shortcode ? 'PU-' + shortcode : 'Pending';

  return (
    <>
      <div className='mt-3'>
        <a href='/runs' className="text-link d-flex justify-content-start">
          <Icons.Back className='align-self-center mr-2'></Icons.Back>
          <h4 className='align-self-center mb-0 flex-grow-1'>{pickup_number}</h4>
          <h6 className='align-self-center mb-0 '>Run {runId}</h6>
        </a>
      </div>
      
      <h4 className="mt-3" >{filter(items, 'scanned').length} of {items.length} item(s)</h4>
    </>
  )
}

const scannedRatio = ({ items }: JobType) => ({
  itemsScanned: filter(items, 'scanned').length,
  itemsCount: items.length
})

const ScannedStatus = ({ job }: { job: JobType }) => {
  const { itemsCount, itemsScanned } = scannedRatio(job);
  const variant = itemsScanned < itemsCount ? 'danger font-weight-bold' : 'secondary';
  return(<p className={`text-${variant}`}>{itemsScanned} / {itemsCount} item{itemsCount == 1 ? '': 's'}</p>)
}

const cellRenderer = ({ row: job }: { row: JobType }) => {
  const { itemsCount, itemsScanned } = scannedRatio(job);
  const className = itemsScanned < itemsCount ? 'border-2 border-danger' : ''

  return({
    cell: (<InfoBox title={`Job ${job.id}`} subtitle={job.internal_reference_id ? `Ref. ${job.internal_reference_id}` : ''}>
      <ScannedStatus job={job}/>
    </InfoBox>),
    className
  })
}

const RunShow = ({ run }: PropsType) => {
  return(
    <>
      <Header shortcode={run.shortcode} runId={run.id} items={flatMap(run.jobs, 'items')} />
      <Table
        className='mt-3'
        columns={[{ cellRenderer }]}
        rows={run.jobs}
      />
    </>
  )
}

const RunQuery = ({ id }: { id: string }) => {
  const { startDate, endDate, location } = useOutletContext<ContextType>();

  const params = {
    location,
    datetime_start: startDate.toString(),
    datetime_end: endDate.toString()
  }

  return(
    <PageQuery<RunWithJobsType, RunRequestResponseType>
      requestPath={`runs/${id}`}
      params={params}
      itemFromData={d => d['run']}
      defaultValue={{id: '', shortcode: '', jobs: []}}
      causeUpdate={[id, startDate, endDate, location]}
      OnError={ErrorHandler}
    >
      {({ value }) => (<RunShow run={value} />)}
    </PageQuery>
  )
}

export default RunQuery;

import { ReactNode } from 'react';
import Logo from './Logo';

import { Button } from '../Components';

interface PropsType {
  logout?: () => void;
  title?: ReactNode;
}

const Logout = ({ onClick }: { onClick: () => void }) => (
  <Button className='ml-auto pr-2' onClick={onClick}>Logout</Button>
)

const Title = ({ children }: { children: ReactNode }) => (
  <div className='pl-2'>{children}</div>
)

const Header = ({ logout, title = 'Scan & Sort' }: PropsType) => {
  return(
    <div className='bg-primary m-2 d-flex justify-content-start align-items-center'>
      <Logo />
      <Title>{title}</Title>
      {logout && <Logout onClick={logout}/>}
    </div>
  )
}

export default Header;

import { FC } from 'react';

import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { SessionManager } from '../Services';

import { Header, PageContainer, Card } from '../Components';

interface PropsType {
  redirect?: boolean;
}

const ExternalLayout: FC<PropsType> = ({ redirect = true }) => {
  const location = useLocation();

  if(redirect && SessionManager.get().session) {
    return <Navigate to='/scan' state={{ from: location }} replace />
  }

  return(
    <div className='bg-primary'>
      <PageContainer>
        <Header />
        <Card variant='white'>
          <Outlet />
        </Card>
      </PageContainer>
    </div>
  );
}

export default ExternalLayout;

import { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import * as yup from "yup";
import moment from "moment";
import timezone from 'moment-timezone';

import { Card, Form, InfoBox, Button } from "../Components";
import { PageQuery, ErrorHandler } from "../Services";
interface QueryPropsType {
  id?: string;
}

interface PropsType {
  id?: string;
  item?: ItemType;
  setId: (id: string) => void;
}

const validationSchema = yup.object().shape({
  code: yup.string().required("Barcode is required"),
});

interface ScanFormType {
  onChange: (id: string) => void;
}

const ScanForm = ({ onChange: onClick }: ScanFormType) => {
  const [isBlur, setIsBlur] = useState<boolean>(false);

  return (
    <Form.Form
      className="d-flex justify-content-between mb-3"
      validationSchema={validationSchema}
      initialValues={{ code: "" }}
      onSubmit={({ code }, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        onClick(code || "");
        resetForm();
        setSubmitting(false);
      }}>
      {({ fieldValues }) => (
        <Form.Field
          className="w-100 pt-1"
          placeholder={isBlur ? 'Tap here to start scanning' : "e.g. 987654321-01"}
          name="code"
          type="text"
          autoFocus
          blurred={isBlur}
          {...fieldValues}
          handleBlur={() => {
            setIsBlur(true);
            onClick("");
          }
          }
          handleFocus={() => {
            setIsBlur(false);
          }
          }
        />
      )}
    </Form.Form>
  );
}

const ItemNotFound = () => {
  return (
    <Card variant="light" className="mb-3 border-2 border-danger">
      <InfoBox title="Item not found" subtitle="Barcode not recognised" />
    </Card>
  );
};

const ReferenceLine = ({
  jobId,
  internalRef,
}: {
    jobId: string;
    internalRef?: string;
  }) => {
  return (
    <div className="mt-1 text-secondary d-flex flex-wrap">
      <div>{`Job ${jobId}`}</div>
      {internalRef && (
        <>
          <div className="px-1">|</div>
          <div>{`Ref. ${internalRef}`}</div>
        </>
      )}
    </div>
  );
};

const AdhocJobCard = ({
  readyAt,
  jobId,
  internalReference,
}: {
    readyAt: string;
    jobId: string;
    internalReference?: string;
  }) => {
  return (
    <Card variant="light" className="border-2 border-warning">
      <InfoBox title="Ad-hoc job" subtitle={readyAt}>
        <ReferenceLine jobId={jobId} internalRef={internalReference} />
      </InfoBox>
    </Card>
  );
};

const RunNotFoundCard = ({
  jobId,
  internalReference,
}: {
    jobId: string;
    internalReference?: string;
  }) => {
  return (
    <Card variant="light" className="border-2 border-warning">
      <InfoBox title="Run not found" subtitle="Pending run creation">
        <ReferenceLine jobId={jobId} internalRef={internalReference} />
      </InfoBox>
    </Card>
  );
};

const RunCard = ({ item }: { item: ItemType | undefined }) => {
  if (!item) {
    return <ItemNotFound />;
  }

  const { job_id, ad_hoc, internal_reference, run } = item;

  if (!run.id) {
    return ad_hoc ? (
      <AdhocJobCard
        readyAt={run.ready_at.format("h:mm a")}
        jobId={job_id}
        internalReference={internal_reference}
      />
    ) : (
        <RunNotFoundCard jobId={job_id} internalReference={internal_reference} />
      );
  }

  return (
    <Card variant="light" className="border-2 border-primary-blink">
      <InfoBox
        title={run.shortcode || 'Pending'}
        subtitle={run.ready_at.format('h:mm a')}
        href={`/runs/${run.id}`}
      >
        <ReferenceLine jobId={job_id} internalRef={internal_reference} />
      </InfoBox>
    </Card>
  );
};

const FilterInfo = () => {
  const { startDate: date, location } = useOutletContext<ContextType>();

  return (
    <p className="text-secondary">
      {location} - {date.format("ddd D MMM, YYYY")}
    </p>
  );
};

const ScanInfo = () => {

  return (
    <p className="text-secondary mb-6">
      {"Scan or manually enter all items, then select 'Review'."}
    </p>
  )
}

const ReviewButton = () => {
  return (
    <a href="/runs" className="pt-4">
      <Button className="w-100"> Review </Button>
    </a>
  );
};

export const Scan = ({ id, item, setId }: PropsType) => {
  return (
    <>
      <ScanForm onChange={setId}/>
      {id && <RunCard item={item} />}
      {!id && <ScanInfo />}
      <FilterInfo />
      <ReviewButton />
    </>
  );
};

const itemFromData = (ctx: ContextType) => (data: ItemRequestResponseType) => {
  const itemData = data.delivery_items[0];
  if (!itemData) {
    return undefined
  }

  const { startDate, endDate, location } = ctx;
  // replace run ready at with moment instance
  const item = {
    ...itemData,
    run: {
      ...itemData.run,
      ready_at: moment(timezone.tz(itemData.run.ready_at, `Australia/${location}`)),
    }
  }

  // return nothing if not in filtered range
  if (
    (item.location && item.location != location) ||
    !item.run.ready_at.isBetween(startDate, endDate)
  ) {
    return undefined;
  }

  return item;
}

const ScanPage = ({ id: defaultId }: QueryPropsType) => {
  const [id, setId] = useState<string>(defaultId || "");
  const navigate = useNavigate();
  const ctx = useOutletContext<ContextType>();

  const changeRoute = (id: string) => {
    setId(id);
    navigate(`/scan/${id}`);
  };

  if (!id) {
    return <Scan setId={changeRoute} />;
  }

  const { startDate, endDate, location } = ctx;

  return (
    <PageQuery<ItemType | undefined, ItemRequestResponseType>
      defaultValue={undefined}
      requestPath={`delivery_items/${id}?time_stamp=${moment().valueOf()}`}
      itemFromData={itemFromData(ctx)}
      causeUpdate={[id, startDate, endDate, location]}
      OnError={ErrorHandler}
    >
      {({ value }) => <Scan id={id} item={value} setId={changeRoute} />}
    </PageQuery>
  );
};

export default ScanPage;

import { Nav as BootstrapNav } from "react-bootstrap";
import { lowerCase } from "lodash";

interface ItemProps {
  href?: string;
  name: string;
  active?: boolean;
  disabled?: boolean;
  className?: string;
}

interface NavProps {
  variant?: "tabs" | "pills";
  links: ItemProps[];
  className?: string;
}

function NavItem({
  href,
  name,
  active = false,
  disabled = false,
  className = "",
}: ItemProps) {
  return (
    <BootstrapNav.Item className={className}>
      <BootstrapNav.Link
        href={href || `/${lowerCase(name)}`}
        active={active}
        disabled={disabled}
      >
        {name}
      </BootstrapNav.Link>
    </BootstrapNav.Item>
  );
}

function Nav({ variant = "tabs", links, className = "" }: NavProps) {
  return (
    <BootstrapNav variant={variant} className={className}>
      {links.map((props: ItemProps, index) => (
        <NavItem {...props} key={index}></NavItem>
      ))}
    </BootstrapNav>
  );
}

export default Nav;

import { getCookie, setCookie, removeCookie } from 'typescript-cookie'
import moment from 'moment';

interface SetSessionType {
  accessToken: string;
  expiresIn: number;
  refreshToken: string;
  tokenType: string;
}

type SessionType = SetSessionType & {
  expiresAt: moment.Moment | undefined;
  session: boolean;
}

type SessionGetType = () => SessionType;
type SessionSetType = (val: SetSessionType) => void;
type SessionDeleteType = () => void;

const cookieName = 'session';

const sessionGet: SessionGetType = () => {
  const data = JSON.parse(getCookie(cookieName) || '{}')
  data['expiresAt'] = data['expiresAt'] ? moment(data['expiresAt']) : undefined;
  data['session'] = (data['expiresAt'] && data['expiresAt'] > moment()) || false;

  return data;
}

const sessionSet: SessionSetType = (sessionData: SetSessionType) => {
  const expiresAt = moment().add(sessionData.expiresIn, 'm').toDate();
  setCookie(cookieName, JSON.stringify({ ...sessionData, expiresAt }));
}

const sessionDelete: SessionDeleteType = () => {
  removeCookie(cookieName);
}

const Exportable = { get: sessionGet, set: sessionSet, delete: sessionDelete }
export default Exportable;

import { useState, ChangeEvent } from 'react';
import * as yup from 'yup';
import { AxiosResponse, AxiosError } from 'axios';

import { FormikHelpers } from 'formik';
import { SessionManager, request } from '../Services';
import { Form, FormDataType, Card } from '../Components';
import { useNavigate, useSearchParams } from 'react-router-dom';

const validationSchema = yup.object().shape({
  username: yup.string().required('Email is required').email('Email must be a valid email'),
  password: yup.string().required('Password is required').min(6, 'Must be at least 6 characters')
})

interface AuthModel extends FormDataType {
  username: string;
  password: string;
}

const initialValues: AuthModel = { username: '', password: '' };

const onSuccess = (navigate: (path: string) => void) => ({ data }: AxiosResponse<AuthResponseType>) => {
  navigate('/scan');
  SessionManager.set({
    accessToken: data['access_token'],
    expiresIn: data['expires_in'],
    refreshToken: data['refresh_token'],
    tokenType: data['token_type']
  });
}

const signIn = ({ navigate, setError }: { navigate: (path: string) => void, setError: (err: string) => void }) => async ({ username, password }: AuthModel, { setSubmitting }: FormikHelpers<AuthModel>) => {
  setSubmitting(true);
  const data = { username, password, grant_type: 'password', client_id: 'user' }

  await request.post<AuthModel, AuthResponseType>({
    path: 'oauth/token', data, authed: false
  }).then(onSuccess(navigate))
    .catch((error: AxiosError<AuthResponseErrorType>) => {
      setError(error.response?.data?.error_description || error.message);
    })
    .finally(() => setSubmitting(false));
};

const SessionExpiredBanner = () => (
  <Card variant='danger' className='mb-2'>Session has expired, please reauthenticate</Card>
)

const SignInForm = () => {
  const [errorMessage, setError] = useState('');
  const navigate = useNavigate();
  const [params] = useSearchParams()

  return(
    <>
      {(params.get('session') === 'expired') && <SessionExpiredBanner />}
      <Form.Form
        submitContent='Log in'
        submitButtonClassName='pt-4'
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={signIn({ navigate, setError })}>
        {({ fieldValues }) => {
          const onChange = (val: string | ChangeEvent<AuthModel>) => {
            setError('');
            fieldValues.handleChange(val)
          }

          return(
            <>
              <h1>Log in</h1>
              <Form.Field placeholder='Email Address' name="username" type="email" {...fieldValues} handleChange={onChange} />
              <Form.Field placeholder='Password' name="password" type="password" {...fieldValues} handleChange={onChange} />
              <Form.ErrorMessage message={errorMessage} />
            </>
          )
        }}
      </Form.Form>
    </>
  );
}

export default SignInForm;

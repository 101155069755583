import { useState, ReactNode, ReactElement } from 'react';
import BootStrapModal from 'react-bootstrap/Modal';

type OnClickType = () => void;
type ShowType = boolean;

interface PropsType {
  Header?: ReactNode;
  Trigger: (onClick: OnClickType) => ReactElement;
  children: (p: { hide: () => void }) => ReactNode;
  defaultShow?: ShowType;
}

const Modal = ({ Header=<></>, Trigger, children, defaultShow=false }: PropsType) => {
  const [show, setShow] = useState<ShowType>(defaultShow);

  const hide = () => setShow(false);

  return(
    <>
      {Trigger(() => setShow(true))}
      <BootStrapModal show={show} onHide={hide}>
        {Header && (
          <BootStrapModal.Header className='text-secondary pb-0' closeButton>
            {Header}
          </BootStrapModal.Header>
        )}
        <BootStrapModal.Body className='pt-0'>
          {children({ hide })}
        </BootStrapModal.Body>
      </BootStrapModal>
    </>
  )
};

export default Modal;

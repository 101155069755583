import { ReactNode, useState, useEffect, ReactElement } from 'react';
import { AxiosResponse } from 'axios';
import request from './Request';
import history from './history';
import { Loading } from '../Components';

interface PageQueryPropsType<ValueType, ResponseType> {
  children: (val: { value: ValueType }) => ReactNode;
  requestPath: string;
  params?: ParamsType;
  causeUpdate?: (string | moment.Moment)[];
  itemFromData: (res: ResponseType) => ValueType;
  defaultValue: ValueType;
  OnError?: (p: { error: ServerErrorType }) => ReactElement;
}

function PageQuery<ValueType, ResponseType>({ children, requestPath, causeUpdate=[], itemFromData, defaultValue, params={}, OnError }: PageQueryPropsType<ValueType, ResponseType>) {
  const [value, setValue] = useState<ValueType>(defaultValue);
  const [state, setState] = useState<RequestStateType>('pending');
  const [err, setError] = useState<ServerErrorType | undefined>(undefined);

  const getItem = async () => {
    request.get<ResponseType>({ path: requestPath, params })
           .then(({ data }: AxiosResponse<ResponseType>) => {
             setValue(itemFromData(data))
             setState('done')
           }).catch((err) => {
             setError(err);
             setState('error');
           })
  };

  useEffect(() => {
    getItem();
    setState('pending');
  }, causeUpdate);

  if(state === 'pending') {
    return <Loading/>
  } else if(state === 'error') {
    if(OnError && err) {
      return <OnError error={err as ServerErrorType} />
    } else {
      history.push('/error')
    }
  }

  return(<>{children({ value })}</>)
}

export default PageQuery;

import ErrorPage from '../Pages/ErrorPage'
import Bugsnag from '@bugsnag/js';

const ErrorHandler = ({ error }: { error: ServerErrorType }) => {
  const { response } = error
  if (response) {
    const { status, data } = response
    Bugsnag.notify(new Error(error.name), function (event) {
      event.addMetadata('message', { status: status, data: data })
    })

    return (
      <ErrorPage errorMessage={data?.error} />
    )
  }

  return <ErrorPage />
}

export default ErrorHandler;
import axios, { AxiosStatic, AxiosResponse } from 'axios'
import { SessionManager } from '.';
import history from '../Services/history';

interface GetPropsType {
  path: string;
  authed?: boolean;
  params: ParamsType;
}

const attachInterceptors = (a: AxiosStatic) => {
  a.interceptors.response.use((r) => (r), (error) => {
    if(error.response?.status === 401) {
      SessionManager.delete();
      history.push('/login?session=expired');
    }
     return Promise.reject(error)
  });
}

const endPoint = () => (process.env.REACT_APP_API_BASE_URL || '')

const headers = (authed: boolean) => {
  const basicHeader = {
    'X-App-Token': 'scan_sherpa_web',
  }

  return authed ? {
    ...basicHeader,
    'Authorization': `Bearer ${SessionManager.get().accessToken}`
  } : basicHeader
}

async function get<ResponseType>({ path, params, authed=true }: GetPropsType) {
  if(authed) { attachInterceptors(axios) }

  return await axios.get<ResponseType>(
    `${endPoint()}/${path}`,
    { headers: headers(authed), params }
  )
}

interface PostPropsType<D> {
  path: string;
  data: D;
  authed?: boolean
}

async function post<DataType, ResponseType>({ path, data, authed=true }: PostPropsType<DataType>) {
  if(authed) { attachInterceptors(axios) }

  return await axios.post<DataType, AxiosResponse<ResponseType>>(`${endPoint()}/${path}`, data, {
    headers: headers(authed)
  })
}

export default { get, post };

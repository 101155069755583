import './Styles/App.scss';
import { Bugsnag, Router } from './Services';

const BugsnagApp = () => (
  <Bugsnag>
    <Router />
  </Bugsnag>
)

export default BugsnagApp;

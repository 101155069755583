import { ReactNode, isValidElement } from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';

import { map, some } from 'lodash';

interface CellProps<T> {
  row: T;
  rowIndex: number;
  columnIndex: number
}

type CellInfoReturnType = { cell: ReactNode, className?: string };
type CellRenderReturnType = ReactNode;

interface ColumnType<T> {
  header?: ReactNode;
  // cellRenderer can return either a reactnode or an object with cell: reactnode, className: string
  cellRenderer: (cellProps: CellProps<T>) => (CellInfoReturnType | CellRenderReturnType);
}

interface TablePropsType<T> {
  className?: string;
  indexStart?: number;
  columns: ColumnType<T>[];
  rows: T[];
}

function HeaderCell({ header }: { header: ReactNode | undefined, index: number }){
  return(
    <th className='border-0 text-secondary'>{header}</th>
  )
}

interface CellRendererType<T> {
  column: ColumnType<T>;
  row: T;
  rowIndex: number;
  columnIndex: number;
}

// check if cellRenderer is returning a react node or an object with cell and className
function isACellInfoReturnType(obj: CellInfoReturnType | CellRenderReturnType): obj is CellInfoReturnType {
  return !!(obj && !isValidElement(obj))
}

function CellRenderer<T>({ column, row, rowIndex, columnIndex }: CellRendererType<T>) {
  let cell, className
  const renderer = column.cellRenderer({ row, rowIndex, columnIndex })

  if(isACellInfoReturnType(renderer)) {
    ({ cell, className } = renderer)
  } else {
    cell = renderer;
  }

  return(
    <td className={className || 'border-0'}>{cell}</td>
  )
}

interface RowRendererType<T> {
  indexStart: number;
  columns: ColumnType<T>[];
  row: T;
  index: number;
}

function RowRenderer<T>({ indexStart, columns, row, index }: RowRendererType<T>) {
  return(
    <tr>
      {map(columns, (column, columnIndex) => (
        <CellRenderer<T> key={`Cell-${index}-${columnIndex}`} column={column} columnIndex={columnIndex + indexStart} rowIndex={index} row={row} />
      ))}
    </tr>
  )
}

function Table<T>({ className, columns, rows, indexStart=0 }: TablePropsType<T>) {
  return(
    <BootstrapTable className={`border-0 ${className}`} striped bordered>
      <thead>
        { some(columns, 'header') && (
          <tr>
            {map(columns, (column, i) => (<HeaderCell key={`headerCell-${i}`} header={column.header} index={i + indexStart} />))}
          </tr>)
        }

      </thead>
      <tbody>
        {
          map(rows, (row, index) => (
            <RowRenderer<T> key={`Row-${index}`} indexStart={indexStart} columns={columns} row={row} index={index + indexStart} />)
          )
        }
      </tbody>
    </BootstrapTable>
  )
}
export default Table;

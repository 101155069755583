import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import CustomRouter from './CustomRouter';
import RoutedComponent from './RoutedComponent';
import { history } from '../'

import { AuthedLayout, ExternalLayout } from '../../Layouts';
import { ErrorPage, NotFound, Scan, SignIn, RunList, RunShow } from '../../Pages';

const RedirectTo = ({ path }: { path: string }) => {
  const location = useLocation();

  return(
    <Navigate to={path} state={{ from: location }} replace />
  )
}

const Router = () => (
  <CustomRouter history={history}>
    <Routes>
      <Route element={<AuthedLayout />}>
        <Route path="/scan" element={<Scan />} />
        <Route path="/scan/:id" element={<RoutedComponent<{ id: string }> render={({ id }) => <Scan    id={id}/>} />} />
        <Route path="/runs/:id" element={<RoutedComponent<{ id: string }> render={({ id }) => <RunShow id={id}/>} />} />
        <Route path="/runs" element={<RunList />} />
      </Route>

      <Route element={<ExternalLayout />}>
        <Route path="/login" element={<SignIn />} />
        <Route path="/" element={<RedirectTo path='/login' />} />
      </Route>

      <Route element={<ExternalLayout redirect={false} />}>
        <Route path="/error" element={<ErrorPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>

    </Routes>
  </CustomRouter>
);

export default Router;

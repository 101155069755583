import { ReactNode, useState, useLayoutEffect } from 'react';
import { Router as BrowserRouter } from "react-router-dom";
import { BrowserHistory } from '../';

interface CustomRouterProps {
  history: BrowserHistory;
  children: ReactNode;
}

const CustomRouter = ({ history, ...props }: CustomRouterProps) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <BrowserRouter
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};


export default CustomRouter;

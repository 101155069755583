import { ReactNode } from 'react';

import Container from 'react-bootstrap/Container';

interface PropsType {
  children: ReactNode
}

const PageContainer = ({ children }: PropsType) => (
  <Container>{children}</Container>
);

export default PageContainer;

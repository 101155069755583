import React from 'react';

import BugsnagClient from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

interface PropsType {
  children: React.ReactNode;
}

const bugsnagApiKey: string = (process.env.REACT_APP_BUGSNAG_API_KEY || '');

const ErrorView = () => (
  <div>
    <p>An Error Has Occurred</p>
  </div>
);

const Bugsnag = ({ children }: PropsType) => {
  if(!bugsnagApiKey) {
    return(<>{children}</>);
  }

  BugsnagClient.start({
    apiKey: bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.REACT_APP_ENV,
    enabledReleaseStages: ['production', 'qa', 'dev'],
  })

  const ErrorBoundary = BugsnagClient.getPlugin('react')?.createErrorBoundary(React)

  return ErrorBoundary ? (
    <ErrorBoundary FallbackComponent={ErrorView}>
      {children}
    </ErrorBoundary>
  ) : (
    <>{children}</>
  )
}

export default Bugsnag;

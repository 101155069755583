import { useState, ReactNode } from 'react';
import moment from 'moment';
import { map } from 'lodash';
import { Card, Button } from './';

type DateType = moment.Moment;

interface PropsType {
  endDate: DateType;
  setEndDate: (date: DateType) => void;
  startDate: DateType;
  setStartDate: (date: DateType) => void;
  location: LocationType;
  setLocation: (l: LocationType) => void;
  onSubmit: () => void;
}

interface SelectableTileProps {
  children: ReactNode;
  selected: boolean;
  onClick?: () => void;
  className?: string;
}

function SelectableTile({ className='', children, selected, onClick }: SelectableTileProps) {
  return(
    <Card
      className={`w-50 ${selected ? 'border-2 border-primary font-weight-bold ' : ''} ${className}`}
      variant={selected ? 'white' : 'light'}
      onClick={selected ? undefined : onClick}
    >
      {children}
    </Card>
  )
}

const renderDate = ({ date, selected, onClick }: { date: DateType, selected: boolean, onClick: (d: DateType) => void }) => {
  return(
    <SelectableTile key={date.format('dd/mm')} selected={selected} onClick={() => onClick(date)}>
      {date.format('Do MMMM')}
    </SelectableTile>
  )
}

const renderLocation = ({ location, selected, onClick }: { location: LocationType, selected: boolean, onClick: (l: LocationType) => void }) => {
  return(
    <SelectableTile key={location} selected={selected} onClick={() => onClick(location)}>
      {location}
    </SelectableTile>
  )
}

const dates: () => DateType[] = () => [moment(), moment().add(1, 'day')];
const locations: () => LocationType[] = () => ['Brisbane', 'Sydney', 'Melbourne', 'Perth', 'Adelaide'];

const Filters = ({ startDate, setStartDate, setEndDate, location, setLocation, onSubmit }: PropsType) => {
  const [date, setDate] = useState<moment.Moment>(startDate);
  const [city, setCity] = useState<LocationType>(location);

  const handleSubmit = () => {
    setStartDate(date.clone().startOf('day'));
    setEndDate(date.clone().endOf('day'));
    setLocation(city);
    onSubmit();
  };

  return(
    <>
      <h5 className='mb-3'>Date of pick up</h5>
      <div className='d-flex flex-wrap mb-4 justify-content-between'>
        {map(dates(), (d) => renderDate({ date: d, selected: d.isSame(date, 'day'), onClick: setDate }))}
      </div>
      <h5 className='mb-3'>Pick up location</h5>
      <div className='d-flex flex-wrap justify-content-between'>
        {map(locations(), (l) => renderLocation({ location: l, selected: l === city, onClick: setCity }))}
      </div>
      <div>
        <Button className='w-100 mt-4' onClick={handleSubmit}>Apply</Button>
      </div>
    </>
  )
}

export default Filters;

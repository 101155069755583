import { map } from 'lodash';
import moment from 'moment';
import timezone from 'moment-timezone';
import { Table, Icons } from '../Components';
import { filter } from "lodash";
import { useOutletContext } from "react-router-dom";
import { PageQuery, ErrorHandler } from '../Services';

interface PropsType {
  runs: RunType[];
}

const EmptyRunList = () => {
  return <p className="text-secondary">No runs to display</p>;
}

const Header = ({ runs }: { runs: RunType[] }) => {
  return (
    <h4 className="mt-3">
      {filter(runs, function(r) { return r.items_scanned == r.items_count; }).length} of {runs.length} run(s) scanned
    </h4>
  );
};

const ScannedOutPut = ({ run: { items_scanned, items_count } }: { run: RunType }) => {
  const variant = items_scanned < items_count ? 'danger font-weight-bold' : 'secondary';
  return(<p className={`text-${variant} m-0 `}>{items_scanned} / {items_count}</p>)
}

export const columns = [
  {
    header: 'PU#',
    cellRenderer: ({ row }: { row: RunType }) => (<p className='font-weight-bold m-0'>{row.shortcode || 'Pending'}</p>)
  },
  {
    header: 'Pickup',
    cellRenderer: ({ row }: { row: RunType }) => (<p className='m-0'>{row.ready_at.format('h:mm a')}</p>)
  },
  {
    header: 'Items',
    cellRenderer: ({ row }: { row: RunType }) => (
      <a href={`/runs/${row.id}`} className='d-flex justify-content-between'>
        <ScannedOutPut run={row} />
        <div className="align-self-center">
          <Icons.ChevronRight variant="secondary" />
        </div>
      </a>
    )
  }
];

const RunList = ({ runs }: PropsType) => {
  return (
    <>
      <Header runs={runs} />
      <Table className="mt-3" columns={columns} rows={runs} />
    </>
  );
}

const RunQuery = () => {
  const { startDate, endDate, location } = useOutletContext<ContextType>();
  const resetRun = (run: RunType) => ({ ...run, ready_at: moment(timezone.tz(run['ready_at'], `Australia/${location}`))})

  const requestPath = `runs`;

  const params = {
    location,
    datetime_start: startDate.toString(),
    datetime_end: endDate.toString()
  }

  return(
    <PageQuery<RunType[], RunListRequestResponseType>
      defaultValue={[]}
      requestPath={requestPath}
      params={params}
      itemFromData={data => map(data['runs'], resetRun)}
      causeUpdate={[startDate, endDate, location]}
      OnError={ErrorHandler}
    >
      {({ value }) => {
        return value.length > 0 ? <RunList runs={value} /> : <EmptyRunList/>
      }}
    </PageQuery>
  )
}

export default RunQuery;

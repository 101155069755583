import { FunctionComponent, SVGProps } from 'react';

import AddIcon from './Add';
import BackIcon from './Back';
import BarcodeIcon from './Barcode';
import ClearIcon from './Clear';
import CalendarIcon from './Calendar';
import ChevronRightIcon from './ChevronRight';
import DropDownIcon from './DropDown';
import FilterIcon from './Filter';
import LocationIcon from './Location';
import LogoutIcon from './Logout';
import RefreshIcon from './Refresh';

type VariantType = 'primary' | 'secondary' | 'white' | 'dark';
type IconVariantType = 'White' | 'Secondary' | 'Dark'; // 'Primary' to add later if needed
type VariantMapType = {
  [key in VariantType]: IconVariantType
};

export interface IconProps {
  onClick?: () => void;
  variant?: VariantType;
  className?: string;
}

type IconType = FunctionComponent<SVGProps<SVGSVGElement> & {
  title?: string | undefined; 
}>

type IconHolder = {
  Dark: IconType,
  Secondary: IconType,
  White: IconType
}

const variantMap: VariantMapType = {
  primary: 'Dark',
  secondary: 'Secondary',
  white: 'White',
  dark: 'Dark'
}

function IconConstructor (Icon: IconHolder) {
  // needed for React displayName functionality
  const iconRenderer = ({ variant='dark', className='', onClick }: IconProps) => {
    const Component = Icon[variantMap[variant]];
    return <span className={className} onClick={onClick}><Component /></span>;
  }
  return iconRenderer;
}

const Add = IconConstructor(AddIcon);
const ChevronRight = IconConstructor(ChevronRightIcon);
const Back = IconConstructor(BackIcon);
const Barcode = IconConstructor(BarcodeIcon);
const Clear = IconConstructor(ClearIcon);
const Calendar = IconConstructor(CalendarIcon);
const DropDown = IconConstructor(DropDownIcon);
const Filter = IconConstructor(FilterIcon);
const Location = IconConstructor(LocationIcon);
const Logout = IconConstructor(LogoutIcon);
const Refresh = IconConstructor(RefreshIcon);

const Exportable = {
  Add, ChevronRight, Back, Barcode, Clear, Calendar, DropDown,
  Filter, Location, Logout, Refresh
};

export default Exportable;

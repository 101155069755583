import React from 'react';

import { Button as BootstrapButton, ButtonProps } from 'react-bootstrap';

const Button: React.FC<ButtonProps> = (props) => {
  const className = `shadow-none font-weight-bold ${props.className}`;
  return <BootstrapButton {  ...props } className={className} />
}

export default Button;
